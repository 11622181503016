<template>
  <div class="top_select">
    <div>
      <el-button
        icon="RefreshRight"
        size="small"
        style="margin-right: 5px"
        @click="init()"
      ></el-button>
      <el-input
        size="small"
        placeholder="请输入客户名称/编号"
        v-model.trim="listQuery.name"
        style="width: 200px"
        @keyup.enter="init"
        clearable
      ></el-input>
      <el-button
        size="small"
        type="primary"
        @click="init"
        style="margin-right: 10px"
        icon="Search"
        >搜索</el-button
      >
      <search @success="init" @cancel="cancel">
        <el-form
          style="width: 550px"
          label-width="90px"
          size="small"
          class="style_form"
        >
          <el-form-item label="开始日期：">
            <el-date-picker
              style="width: 150px"
              v-model="listQuery.beginTime"
              type="date"
              placeholder="起始日期"
              clearable
              value-format="YYYY-MM-DD"
            />
            &nbsp;-&nbsp;
            <el-date-picker
              style="width: 150px"
              v-model="listQuery.endTime"
              type="date"
              placeholder="终止日期"
              clearable
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div></div>
  </div>
  <el-table
    :data="list"
    style="width: 100%"
    border
    :height="contentStyleObj"
    @selection-change="handleSelectionChange"
    v-loading="loading"
  >
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column type="selection" width="55" align="center" />
    <el-table-column prop="number" label="编号"> </el-table-column>
    <el-table-column prop="customerName" label="客户名称"> </el-table-column>
    <el-table-column prop="date" label="日期"> </el-table-column>
    <el-table-column prop="receiveName" label="接收人"> </el-table-column>
    <el-table-column prop="transferName" label="移交人"> </el-table-column>
    <el-table-column prop="goodsType" label="物品类型"> </el-table-column>
    <el-table-column prop="goodsName" label="物品名称"> </el-table-column>
    <el-table-column prop="goodsNum" label="数量"> </el-table-column>
    <el-table-column prop="courierNumber" label="快递单号"> </el-table-column>
    <el-table-column prop="explain" label="其他说明"> </el-table-column>
    <el-table-column prop="handoverStatus" label="交接状态">
      <template #default="scope">
        <span
          :class="
            scope.row.handoverStatus == '已归还待确认' ? 'hand_style' : ''
          "
        >
          {{ scope.row.handoverStatus }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="inventoryName" label="操作">
      <template #default="scope">
        <el-button
          link
          size="small"
          @click="handSure(scope.row)"
          v-if="scope.row.handoverStatus == '已归还待确认'"
          >确认</el-button
        >
        <el-button link size="small" @click="checkInfo(scope.row)" v-else
          >详情</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <goodsBack
    ref="goodsBackRef"
    :userOptions="props.userOptions"
    @success="init"
  />
</template>
<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { goodsList, goodsInfo } from "@/api/newCrm";
import goodsBack from "./goodsBack.vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  userOptions: {
    type: Array,
  },
});
const listQuery = ref({
  page: 1,
  limit: 20,
});
const list = ref([]);
const total = ref(0);
const loading = ref(false);
const contentStyleObj = ref({});
const goodsBackRef = ref()
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(260);
});
const init = () => {
  loading.value = true;
  goodsList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};
//确认
const handSure = (row) => {
  goodsBackRef.value.init(row, "确认");
};
//查看详情
const checkInfo = (row) => {
  goodsInfo({ id: row.id }).then((res) => {});
};

defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hand_style {
  color: #ff6633;
}
</style>
