<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
    <el-tab-pane label="物品仓库" name="1">
      <goodsStash ref="goodsStashRef" :userOptions="userOptions" />
    </el-tab-pane>
    <el-tab-pane label="交接记录" name="2">
      <deliveryRecord ref="deliveryRecordRef" :userOptions="userOptions" />
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import { ref, onMounted } from "vue";
import goodsStash from "./components/goodsStash.vue";
import deliveryRecord from "./components/deliveryRecord.vue";
import { findPeople } from "@/api/base";
const activeName = ref("1");
const goodsStashRef = ref();
const deliveryRecordRef = ref();
const userOptions = ref([]);
onMounted(() => {
  getList();
  findPeople({ code: "bookkeeping" }).then((res) => {
    if (res.data.msg == "success") {
      userOptions.value = res.data.data.list;
    }
  });
});
const getList = () => {
  if (activeName.value == "1") {
    goodsStashRef.value.init();
  } else {
    deliveryRecordRef.value.init();
  }
};

const handleClick = (tab) => {
  activeName.value = tab.props.name;
  getList();
};
</script>
<script>
export default {
  name: "deliveryGood",
};
</script>
