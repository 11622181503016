<template>
  <el-drawer
    v-model="drawer"
    :close-on-click-modal="false"
    size="850px"
    title="归还物品"
  >
    <div v-if="form.handoverStatus == '已归还待确认'">
      <el-alert
        title="请填完所有带 * 号的必填项再提交"
        type="warning"
        show-icon
      />
    </div>
    <div class="steps">
      <n-steps :current="currentStatus" status="process" size="small">
        <n-step
          title="提交归还单"
          :class="
            form.handoverStatus == '已归还待确认' ? 'step_item' : 'step_success'
          "
        >
          <el-button
            size="small"
            type="primary"
            @click="save"
            v-if="form.handoverStatus == '已归还待确认'"
            >提交</el-button
          >
        </n-step>
        <n-step
          title="完成交接确认"
          :class="form.handoverStatus == '已归还' ? 'step_item' : ''"
        >
          <el-button
            size="small"
            type="primary"
            @click="handleSure"
            v-if="form.handoverStatus == '已归还'"
            >完成</el-button
          >
        </n-step>
      </n-steps>
    </div>
    <el-form
      :model="form"
      label-width="90px"
      :rules="rules"
      size="small"
      ref="ruleFormRef"
      :inline="true"
    >
      <div class="record">基本信息：</div>
      <el-form-item label="客户名称" prop="customerId">
        <div class="w280">
          {{ form.customerName }}
        </div>
      </el-form-item>
      <el-form-item label="归还日期" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          placeholder="请选择归还日期"
          value-format="YYYY-MM-DD"
          style="width: 280px"
        />
      </el-form-item>
      <el-form-item label="归还编号" prop="number">
        <el-input
          v-model="form.number"
          placeholder="请输入归还编号"
          class="w280"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="cnName">
        <div class="w280">
          {{ cnName }}
        </div>
      </el-form-item>
      <el-form-item label="移交人" prop="receiveName">
        <select-common
          placeholder="请选择移交人"
          v-model:modelValue="form.receiveName"
          :options="props.userOptions"
          valueKey="CnName"
          labelKey="CnName"
          class="w280 margin0"
        />
      </el-form-item>
      <el-form-item label="接收人" prop="transferName">
        <el-input
          v-model="form.transferName"
          placeholder="请输入接收人"
          class="w280"
        ></el-input>
      </el-form-item>
      <el-form-item label="接收人电话" prop="transferTel">
        <el-input
          v-model="form.transferTel"
          placeholder="请输入接收人电话"
          class="w280"
        ></el-input>
      </el-form-item>
      <el-form-item label="快递单号" prop="courierNumber">
        <el-input
          v-model="form.courierNumber"
          placeholder="请输入快递单号"
          class="w280"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="record">归还物品：</div>
    <el-table :data="form.children" style="width: 100%" border>
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column width="150">
        <template #header> <span class="red">*</span>物品名称 </template>
        <template #default="{ row }">
          {{ row.goodsName }}
        </template>
      </el-table-column>
      <el-table-column label="物品类型" width="80" prop="goodsType">
      </el-table-column>
      <el-table-column prop="goodsNum" label="数量" width="100">
        <template #default="{ row }">
          <el-input-number
            v-model="row.goodsNum"
            :min="1"
            size="small"
            class="w80"
            disabled
          /> </template
      ></el-table-column>
      <el-table-column prop="goodsUnit" label="单位" width="100">
        <template #default="{ row }">
          <el-input v-model="row.goodsUnit" size="small"></el-input> </template
      ></el-table-column>
      <el-table-column prop="remark" label="备注">
        <template #default="{ row }">
          <el-input v-model="row.remark" size="small"></el-input> </template
      ></el-table-column>
    </el-table>
    <div class="record">上传物品附件：</div>
    <FileUploader @change="updateFileList" :initialFileList="form.fileList" />
    <div class="record">其他说明：</div>
    <el-input
      v-model="form.explain"
      maxlength="200"
      style="width: 810px"
      placeholder="请输入说明"
      show-word-limit
      type="textarea"
      :autosize="{ minRows: 4, maxRows: 8 }"
    />
  </el-drawer>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="完成交接确认"
    width="650px"
    destroy-on-close
  >
    <el-form :model="form" size="small">
      <el-form-item label="客户确认时间" prop="confirmDate">
        <el-date-picker
          v-model="form.confirmDate"
          type="date"
          placeholder="请选择客户确认时间"
          value-format="YYYY-MM-DD"
          style="width: 150px"
        />
      </el-form-item>
      <el-form-item label="上传确认资料" prop="confirmDate" required>
        <div class="w520">
          <FileUploader @change="updateImgList" :initialFileList="form.imgList" />
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="makeSure()"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, defineProps, defineExpose } from "vue";
import { saveReturnGoods, confirmReturnGoods } from "@/api/newCrm";
import { ElMessage } from "element-plus";
import { getCurrentDate } from "@/utils/fun";
import FileUploader from "@/components/uploadImg/FileUploader.vue";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const props = defineProps({
  userOptions: {
    type: Array,
  },
});
const drawer = ref(false);
const cnName = proxy.$store.getters["user/user"].cnName;
const form = ref({
  handoverStatus: "已归还待确认",
  fileList: [],
  date: getCurrentDate(),
  type: "0",
  children: [
    {
      goodsNum: 1,
    },
  ],
});
const dialogFormVisible = ref(false);
const rules = ref({
  customerId: [{ required: true, message: "请选择客户", trigger: "change" }],
  date: [{ required: true, message: "请选择归还日期", trigger: "change" }],
  number: [{ required: true, message: "请输入归还编号", trigger: "blur" }],
  receiveName: [{ required: true, message: "请选择移交人", trigger: "change" }],
  transferName: [{ required: true, message: "请输入接收人", trigger: "blur" }],
});
const ruleFormRef = ref();
const currentStatus = ref("1");
const init = (item, type) => {
  if (type == "提交") {
    form.value = {
      handoverStatus: "已归还待确认",
      date: getCurrentDate(),
      type: "1",
      children: item,
      customerId: item[0].customerId,
      fileList: [],
      customerName: item[0].customerName,
    };
    currentStatus.value = "1";
  } else {
    form.value = Object.assign({}, item);
    form.value.children = [item];
    form.value.handoverStatus = "已归还";
    currentStatus.value = "2";
    form.value.confirmDate = getCurrentDate();
    form.value.imgList = [];
  }
  drawer.value = true;
};
const updateFileList = (e) => {
  form.value.fileList = e;
};
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      let arr = [];
      arr = form.value.fileList.map((v) => {
        return v.url;
      });
      form.value.file = arr.join(",");
      saveReturnGoods(form.value).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("保存成功");
          drawer.value = false;
          emit("success");
        }
      });
    }
  });
};
//标记完成
const handleSure = () => {
  dialogFormVisible.value = true;
};
const updateImgList = (e) => {
  form.value.imgList = e;
};
const makeSure = () => {
  if(form.value.imgList.length == 0){
    ElMessage.warning("请上传确认资料");
    return
  }
  let arr = [];
  arr = form.value.imgList.map((v) => {
    return v.url;
  });
  form.value.img = arr.join(",");
  confirmReturnGoods(form.value).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("已交接");
      emit("success");
      dialogFormVisible.value = false;
      drawer.value = false;
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin: 10px 0;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.w280 {
  width: 280px !important;
}
.margin0 {
  margin: 0 !important;
}
.w80 {
  width: 80px !important;
}
.red {
  color: #f00;
  margin-right: 3px;
  font-size: 15px;
}
.del_btn {
  margin-left: 0;
  color: #fe696a;
}
:deep(.el-upload-dragger) {
  padding: 2px 10px;
}
.steps {
  width: 100%;
  margin-top: 10px;
  background-color: #e7fcff;
  padding: 10px 16px;
  border-radius: 4px;
}
.step_item {
  --n-indicator-color: var(--themeColor, #17a2b8) !important;
  --n-indicator-border-color: var(--themeColor, #17a2b8) !important;
}
.step_success {
  --n-splitor-color: var(--themeColor, #17a2b8) !important;
  --n-indicator-border-color: var(--themeColor, #17a2b8) !important;
  --n-indicator-text-color: var(--themeColor, #17a2b8) !important;
}
.w520{
  width: 520px;
}
</style>
