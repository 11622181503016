<template>
  <el-upload
    drag
    action="https://public.listensoft.net/api/uploadImg"
    multiple
    :accept="accept"
    :file-list="fileListPass"
    :http-request="customUpload"
    :on-success="handleSuccess"
    :on-error="handleError"
    :before-upload="beforeUpload"
    :on-remove="handleRemove"
  >
    <slot name="icon">
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    </slot>
    <slot name="text">
      <div class="el-upload__text">拖拽文件到此处或 <em>点击上传</em></div>
    </slot>
  </el-upload>
</template>
<script setup>
import { ref } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
const emit = defineEmits(["change"]);
const props = defineProps({
  initialFileList: {
    type: Array,
    default: () => [],
  },
  accept: {
    type: String,
    default: ".xlsx, .xls, .pdf, .doc, .docx, .jpg, .png, .jpeg",
  },
});
const fileListPass = ref(props.initialFileList);
const baseUrl = ref("https://file.listensoft.net");
//上传文件
const customUpload = (options) => {
  const { action, file, onSuccess, onError } = options;
  console.log(action);

  const formData = new FormData();
  let title = file.name.replace(/,/g, "");
  formData.append("upFile", file, title);
  let config = { headers: { "Content-Type": "multipart/form-data" } };
  axios
    .post(action, formData, config)
    .then((res) => {
      if (res.data.msg == "success") {
        let url = baseUrl.value + res.data.data.url;
        fileListPass.value.push({ url });
        onSuccess(res);
      }
    })
    .catch((error) => {
      onError(error);
    });
};
const handleSuccess = (response, file, fileList) => {
  file.url = baseUrl.value + response.data.data.url;
  ElMessage.success("文件上传成功");
  emit("change", fileListPass.value);
};

const handleError = () => {
  ElMessage.error("文件上传失败");
};
const beforeUpload = (file) => {
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    ElMessage.error("文件大小不能超过10MB");
  }
  return isLt10M;
};
const handleRemove = (file, fileList) => {
  fileListPass.value = fileListPass.value.filter((f) => f.url != file.url);
  ElMessage.success("文件删除成功");
  emit("change", fileListPass.value);
};
</script>
